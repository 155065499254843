import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import ItemService from '../../components/item-service/item.service';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TopBar from '../../components/top-bar/top-bar';
import {
  Button, Container, Section, Wrapper, Image, HighLight,
} from '../../styles/servicios/seguridad.elements';

const ServicePage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Seguridad electronica"}) {
        title
        images {
          gatsbyImageData
        }
      }
    }
  `);
  const { images } = data.contentfulPages;
  return (
    <Layout>
      <main>
        <TopBar title="SERVICIO EN SISTEMAS DE SEGURIDAD ELECTRÓNICA SSE" imageName="nosotros.jpg" next="otros-servicios" previous="redes-electricas" />
        <Section>
          <Wrapper>
            <Container>
              <Image image={getImage(images[0]) as IGatsbyImageData} alt="Camara de seguridad tipo domo y tipo bala" objectFit="contain" />
              <Container className="mid">
                <HighLight>Soluciones enfocadas a cumplir:</HighLight>
                <p>
                  Proyectos / instalaciones para establecimientos industriales y corporativos. Mantenimiento y servicios para establecimientos industriales y corporativos.
                </p>
                <p>
                  Instalación, monitoreo y mantenimiento de alarmas enfocadas tanto a pymes como hogares. Proyectos/instalaciones de alarmas para establecimientos industriales y corporativos.
                </p>
                <HighLight>Con sistemas como:</HighLight>
                <ul>
                  <li>Diseño, instalación y mantenimiento de sistemas de control de acceso, biométricos, teclado, lectora.</li>
                  <li>Diseño, instalación y mantenimiento de sistemas de CCTV.</li>
                  <li>Diseño, instalación y mantenimiento de sistemas de alarmas.</li>
                  <li>Diseño, instalación y mantenimiento de centros de monitoreo.</li>
                </ul>
              </Container>
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container className="gap">
              <ItemService title="Sistemas de CCTV" description="Instalación, diseño y mantenimiento de sistemas de circuito cerrado de televisión que integran visualización, iluminación, grabación, alarmas y notificaciones tiempo real." image={getImage(images[0]) as IGatsbyImageData} altImage="Camara de seguridad tipo domo y tipo bala" />

              <ItemService title="vídeo vigilancia IP" description="La video vigilancia IP aprovecha la red informática de su empresa o residencia sin necesidad de desplegar una infraestructura nueva." image={getImage(images[1]) as IGatsbyImageData} altImage="Camara de seguridad tipo domo" />

              <ItemService title="Video vigilancia hibrida" description="Soporte un sistema CCTV análogo y video vigilancia IP, es la alternativa ideal en caso de querer una actualización del sistema tratando de reducir los costos." image={getImage(images[2]) as IGatsbyImageData} altImage="Camara de seguridad tipo domo y tipo bala fondo gris" />

              <ItemService title="Control de accesos peatonal" description="Soluciones en identificación para permitir o denegar el acceso de personal o visitantes. Tenga control total de que personas ingresan a cada espacio y guarde un registro en el momento en el que lo hicieron." image={getImage(images[3]) as IGatsbyImageData} altImage="Talanqueras de acceso peatonal" />

              <ItemService title="Control de accesos vehicular" description="Implementacion para tener el control de los vehículos que circulan por un espacio público o privado, asegurando el paso a los vehículos permitidos y restringiendo a aquellos que no estén autorizados." image={getImage(images[4]) as IGatsbyImageData} altImage="Talanqueras de acceso vehicular" />

              <ItemService title="Componentes CCTV" description="Cámaras de seguridad de todos los tipos, grabador de vídeo digital (DVR), sistema de vídeo vigilancia inteigente (IVS), discos duros para grabador, video balum, radio enlaces, switch POE, housing y bases" image={getImage(images[5]) as IGatsbyImageData} altImage="Componentes de seguridad electronica" />
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container>
              <Button to="/contactenos">Contactenos <AiFillCaretRight className="plus" /></Button>
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default ServicePage;

export const Head: HeadFC = () => <Seo title="Seguridad electronica" />;
